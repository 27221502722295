import type { CommonLoadingProps } from './types'

// import { Container } from './styles';

const Lips: FCC<CommonLoadingProps> = () => {
  return (
    <img
      src="/images/loading.gif"
      alt="Carregando..."
      loading="eager"
      width={470}
      height={75}
    />
  )
}

export default Lips
