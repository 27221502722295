import Section from 'src/components/sections/Section'
import { useProductsQuery } from 'src/sdk/product/useProductsQuery'
import ProductCard from 'src/components/product/ProductCard'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useLazyQuery } from 'src/sdk/graphql/useLazyQuery'
import { gql } from '@faststore/graphql-utils'
import { useEffect } from 'react'

// import { Container } from './styles';
// const data = [
//   41, 4, 248, 9, 3, 34, 26, 72, 57, 132, 89, 211, 159, 153, 108, 199, 99, 92,
//   160, 80, 131, 142, 205, 207, 229, 244, 385, 375, 389, 249, 281, 303, 304, 269,
//   339, 233,
// ]

const promotionsQuery = gql`
  query PromotionsTeste {
    promotions {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`

const Debug: FCC = () => {
  const rawProducts = useProductsQuery(
    {
      after: '0',
      first: 10,
      sort: 'score_desc',
      term: 'id:33504',
    }
    // {
    //   shouldRetryOnError: false,
    //   fallbackData: { search: { products: { edges: [] } } },
    // }
  )

  const [runQuery] = useLazyQuery(promotionsQuery, {})

  useEffect(() => {
    setTimeout(() => {
      runQuery({})
    }, 5000)
  }, [runQuery])

  return (
    <>
      <GatsbySeo noindex nofollow />

      <div>
        <Section>
          {/* <Embla sliderPerView={5}>
          {Array.from(Array(12).keys()).map((_, index) => (
            <img
              src={`https://cataas.com/cat/says/${index}?width=200&height=420`}
              key={index}
              alt="cat"
            />
          ))}
        </Embla> */}

          {/* <CategoryCarousel
          cmsId="emblaTest"
          categories={data.map((cat) => ({ id: cat }))}
          rows={2}
        /> */}
        </Section>
        <img
          data-fs-image="true"
          src="https://miess.vtexassets.com/unsafe/fit-in/1200x1200/center/middle/https%3A%2F%2Fmiess.vtexassets.com%2Farquivos%2Fids%2F242698%2FHC574_1.jpg%3Fv%3D1756873267"
          srcSet="https://miess.vtexassets.com/unsafe/fit-in/178x178/center/middle/https%3A%2F%2Fmiess.vtexassets.com%2Farquivos%2Fids%2F242698%2FHC574_1.jpg%3Fv%3D1756873267 178w, https://miess.vtexassets.com/unsafe/fit-in/1200x1200/center/middle/https%3A%2F%2Fmiess.vtexassets.com%2Farquivos%2Fids%2F242698%2FHC574_1.jpg%3Fv%3D1756873267 1200w"
          width="200px"
          height="200px"
          alt="Gel Excitante Masculino Volumão 25gr Hot Flowers"
          title="Gel Excitante Masculino Volumão 25gr Hot Flowers"
          sizes="(min-width: 500px) 178px, 1200px"
          loading="lazy"
          decoding="async"
        />

        <pre
          style={{
            fontFamily: '"Fira Code", Consolas',
            margin: '0 auto',
            padding: '20px',
            backgroundColor: '#aaa',
          }}
        >
          {JSON.stringify(rawProducts?.edges, null, 2)}
        </pre>

        {rawProducts && (
          <ProductCard product={rawProducts?.edges[0].node} index={0} />
        )}
      </div>
    </>
  )
}

/**
 * This query is run during SSR
 * */
// export const querySSR = gql`
//   query DebugQuery($slug: String!, $slugB: String!) {
//     product(locator: [{ key: "slug", value: $slug }]) {
//       id: productID
//       slug
//     }
//     productB: product(locator: [{ key: "slug", value: $slugB }]) {
//       id: productID
//       slug
//     }
//   }
// `

// export const getServerData = async () => {
//   const slug = 'camisola-com-fita-em-cetim-renda-branca-hot-love-hl017/p'
//   const slugB =
//     'xana-loka-excitante-feminino-15g-linha-brasileirinhos-hot-flowers-10867-5255/p'

//   try {
//     const { execute } = await import('src/server')
//     const { data } = await execute({
//       operationName: querySSR,
//       variables: { slug, slugB },
//     })

//     console.log({ data })

//     // if (data === null) {
//     //   const originalUrl = `/${slug}/p`

//     //   return {
//     //     status: 301,
//     //     props: {},
//     //     headers: {
//     //       'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
//     //       location: `/404/?from=${encodeURIComponent(originalUrl)}`,
//     //     },
//     //   }
//     // }

//     return {
//       status: 200,
//       props: data ?? {},
//       headers: {
//         'cache-control': 'public, max-age=0, stale-while-revalidate=31536000',
//       },
//     }
//   } catch (err) {
//     console.error(err)

//     return {
//       status: 500,
//       props: {},
//       headers: {
//         'cache-control': 'public, max-age=0, must-revalidate',
//       },
//     }
//   }
// }

export default Debug
