import type { CommonLoadingProps } from './types'
import * as S from './styles'

const Dots: FCC<CommonLoadingProps> = (props) => {
  return (
    <S.DotsContainer className="dots-spinner" {...props}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </S.DotsContainer>
  )
}

export default Dots
