import Dots from './Dots'
import Lips from './Lips'
import type { LoadingProps } from './types'

// import { Container } from './styles';

export const loadingDict = {
  dots: Dots,
  lips: Lips,
}

const Loading: FCC<LoadingProps> = ({ type, ...rest }) => {
  const Component = loadingDict[type]

  return <Component {...rest} />
}

export default Loading
